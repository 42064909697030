<template>
  <v-app app>
    <v-navigation-drawer
      v-model="show_drawer"
      :permanent="!$vuetify.breakpoint.xsOnly"
      style="box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.1)"
      app
    >
      <div class="d-flex align-center">
        <img
          src="/logo.svg"
          width="64"
          height="64"
          style="margin-right: 10px"
        />
        КАБИНЕТ МЕНЕДЖЕРА
      </div>
      <v-list class="pa-0">
        <v-list-item-group active-class="active-item">
          <ns-menu-item
            v-for="item in menu"
            :key="item.label"
            :icon="item.icon"
            :to="{ name: item.path }"
            :label="item.label"
            :active="$route.path.indexOf(item.keyword) !== -1"
          />
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="background-color: #f0f8f6" class="mb-6">
      <v-container fluid class="ma-0 pa-0">
        <v-toolbar height="64x" flat style="z-index: 1">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
            @click.stop="show_drawer = !show_drawer"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <v-toolbar-items class="d-flex">
            <v-expansion-panels hover flat accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0" style="min-height: 100%">
                  <div class="d-flex justify-center align-center pa-0">
                    <v-icon color="primary">mdi-account-circle</v-icon>
                    <span
                      class="user-email text-truncate"
                      style="min-width: 40px"
                      >{{ user.email }}</span
                    >
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="expansion-panel-content-1">
                  <div class="d-flex justify-center align-center">
                    <v-btn
                      depressed
                      color="white"
                      @click="doLogout"
                      class="pa-0 flex-grow-1"
                    >
                      <div>
                        <v-icon color="primary">mdi-exit-run</v-icon>
                        Выход
                      </div>
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-toolbar-items>
        </v-toolbar>
      </v-container>
      <v-container class="px-2 px-sm-10" fluid app>
        <slot />
      </v-container>
    </v-main>
    <v-footer></v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NsMenuItem from "@/components/NsMenuItem";

export default {
  name: "ManagerLayout",
  components: { NsMenuItem },
  data() {
    return {
      show_drawer: false,
      menu: [
        {
          path: "manager-product-categories",
          label: "Категории",
          icon: "mdi-view-list",
          keyword: "categories",
        },
        {
          path: "manager-products",
          label: "Продукты",
          icon: "mdi-apps",
          keyword: "products",
        },
        {
          path: "manager-companies",
          label: "Компании",
          icon: "mdi-domain",
          keyword: "companies",
        },
        {
          path: "manager-users",
          label: "Пользователи",
          icon: "mdi-account-circle",
          keyword: "users",
        },
        {
          path: "manager-patients",
          label: "Пациенты",
          icon: "mdi-account-plus",
          keyword: "patients",
        },
        {
          path: "manager-reports",
          label: "Отчеты",
          icon: "mdi-chart-arc",
          keyword: "reports",
        },
        {
          path: "manager-b2c",
          label: "B2C курсы",
          icon: "mdi-video",
          keyword: "b2c",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["doLogout"]),
  },
};
</script>

<style scoped lang="scss">
.user-email {
  max-width: 200px;
}
#expansion-panel-content-1::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .user-email {
    max-width: 50px;
  }
}
</style>
