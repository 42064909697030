<template>
  <v-container fluid class="mx-0 px-0 my-0 py-0">
    <div class="mb-4 d-flex justify-space-between">
      <v-text-field
        flat
        solo
        v-model="search"
        class="max-width-500"
        style="border: 1px solid #23919e; border-radius: 6px"
        append-icon="mdi-magnify"
        label="Найти"
        single-line
        hide-details
      ></v-text-field>
    </div>
    <div style="max-height: 200px; overflow-y: auto">
      <v-data-table
        class="table"
        :mobile-breakpoint="0"
        :items="value || []"
        :headers="headers"
        @input="itemSelected"
        dense
        multi-sort
        :show-select="selectable"
        :single-select="singleSelect"
        :search="search"
        hide-default-footer
        :items-per-page="-1"
        checkbox-color="primary"
      >
        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              icon
              @click="$emit('doRefundItem', item)"
              color="success"
              v-if="showActions?.refund && item.canRefund"
            >
              <v-icon small>mdi-cash-refund</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="$emit('doEditItem', item)"
              color="primary"
              v-if="showActions?.edit"
            >
              <v-icon small>
                {{
                  content_type === "products" ? "mdi-play" : "mdi-playlist-edit"
                }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="$emit('generateUrl', item)"
              color="primary"
              v-if="showActions?.url"
            >
              <v-icon small>mdi-link-variant</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="$emit('doResetWatchTime', item)"
              color="primary"
              v-if="showActions?.reset"
            >
              <v-icon small>mdi-timer-refresh-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="$emit('doDeleteItem', item)"
              color="error"
              v-if="showActions?.delete"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ObjectGrid",
  components: {},
  props: [
    "content_type",
    "title",
    "value",
    "field-defs",
    "hide-toolbar",
    "show-actions",
    "selectable",
    "single-select",
  ],
  emits: [
    "doEditItem",
    "generateUrl",
    "doDeleteItem",
    "doRefundItem",
    "doResetWatchTime",
    "itemSelected",
  ],
  data() {
    return {
      search: "",
      selected: undefined,
    };
  },
  computed: {
    headers() {
      return [...this.fieldDefs, { value: "actions" }];
    },
  },
  methods: {
    itemSelected(v) {
      this.selected = v;
      this.$emit("itemSelected", v);
    },
  },
};
</script>

<style scoped lang="scss">
table td {
  font-size: 12px;
}
</style>

<style lang="scss">
.table tbody tr:nth-child(even) {
  background-color: #f0f8f6;
}
table thead tr th {
  background-color: #23919e;
  color: white !important;
  font-size: 12px !important;
  font-weight: normal;
}
table tbody tr td {
  font-size: 12px !important;
}
</style>
