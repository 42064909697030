import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=493e117c&scoped=true"
import script from "./AdminLayout.vue?vue&type=script&lang=js"
export * from "./AdminLayout.vue?vue&type=script&lang=js"
import style0 from "./AdminLayout.vue?vue&type=style&index=0&id=493e117c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493e117c",
  null
  
)

export default component.exports