<template>
  <property-form
    :value="
      value || {
        name: '',
        phone: '',
        inn: '',
        ogrn: '',
        email: '',
        trial: '',
        balance: '',
        city: '',
        trial_end_time: '',
        max_patients: '5',
        price_modifier: '1',
      }
    "
    :fields="fieldDefs"
    @input="doSave"
  >
  </property-form>
</template>

<script>
import PropertyForm from "@/components/PropertyForm";
import { mapGetters } from "vuex";
import { VSelect } from "vuetify/lib/components";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import ApiService from "@/api/ApiService";
//import moment from "moment";

export default {
  name: "CompanyForm",
  components: { PropertyForm },
  props: [],
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    ...mapGetters(["user"]),
    value() {
      return this.modalFormComponent?.value;
    },
    managers() {
      return this.modalFormComponent?.managers;
    },
    fieldDefs() {
      if (this.user.role === "manager")
        return {
          max_patients: {
            order: 1,
            label: "Максимум пациентов в пробном периоде",
            visible: true,
            rules: this.patientsRules,
            twoColumns: true,
          },
          price_modifier: {
            order: 2,
            label: "Коэффициент цены",
            visible: true,
            rules: this.priceModifierRules,
            twoColumns: true,
          },
        };

      return {
        name: {
          order: 1,
          label: "Наименование",
          visible: true,
          rules: this.nameRules,
        },
        phone: {
          order: 2,
          label: "Телефон",
          visible: true,
          rules: this.phoneRules,
        },
        email: {
          order: 3,
          label: "E-Mail",
          visible: true,
          rules: this.emailRules,
        },
        inn: { order: 4, label: "ИНН", visible: true },
        ogrn: { order: 5, label: "ОГРН", visible: true },
        trial: {
          order: 6,
          label: "Пробный период",
          component: VSelect,
          items: ["Да", "Нет"],
          visible: true,
          rules: this.trialRules,
        },
        trial_end_time: {
          order: 7,
          label: "Конец пробного периода",
          //component: moment(Datetime).format("YYYY-MM-DD"),
          component: Datetime,
          visible: true,
          rules: this.trialTimeRules,
        },
        balance: {
          order: 8,
          label: "Баланс",
          visible: true,
          rules: this.balanceRules,
        },
        city: {
          order: 9,
          label: "Город",
          visible: true,
          rules: this.cityRules,
        },
        overdraft: {
          order: 10,
          label: "Овердрафт",
          visible: true,
          rules: this.balanceRules,
        },
        manager_id: {
          order: 11,
          label: "Менеджер",
          visible: true,
          component: VSelect,
          items: this.managers?.map((m) => ({
            value: m.id,
            text: m.email,
          })),
        },
        contract: {
          order: 12,
          label: "Договор",
          visible: true,
        },
        max_patients: {
          order: 13,
          label: "Максимум пациентов в пробном периоде",
          visible: true,
          rules: this.patientsRules,
        },
        price_modifier: {
          order: 14,
          label: "Коэффициент цены",
          visible: true,
          rules: this.priceModifierRules,
        },
      };
    },
  },
  data() {
    const modifier = (v) => {
      try {
        return parseFloat(v) >= 1; // value more than 1
      } catch (e) {
        return false;
      }
    };
    return {
      previousBalance: null,
      nameRules: [(v) => !!v || "Обязательное поле"],
      phoneRules: [
        (v) =>
          !v ||
          /^\+\d{1} \(\d{3}\) \d{3} \d{2} \d{2}$/.test(v) ||
          "Пример: +7 (999) 111 22 33",
      ],
      emailRules: [
        (v) => (!!v && !v.includes(" ")) || "Обязательное поле без пробелов",
        (v) => /.+@.+\..+/.test(v) || "Пример: abc@de.fg",
      ],
      trialTimeRules: [(v) => !!v || "Обязательное поле"],
      balanceRules: [
        (v) => !v || /^[0-9]*$/.test(v) || "Значение не может быть меньше 0",
      ],
      cityRules: [(v) => !!v || "Обязательное поле"],
      patientsRules: [
        (v) => !!v || /^[0-9]*$/.test(v) || "Значение не может быть меньше 0",
      ],
      priceModifierRules: [
        (v) => !!v || "Обязательно поле",
        (v) => modifier(v) || "Значение не может быть меньше 1",
      ],
    };
  },
  created() {
    if (this.value) {
      this.previousBalance = this.value.balance;
    }
  },
  methods: {
    doSave(value) {
      // Проверка, изменилось ли значение поля balance
      if (value.balance !== this.previousBalance) {
        this.createReport(value);
      }
      // if (value.trial === "Нет") {
      //   value.formatted_trial_end_time = null;
      // }
      this.$emit("confirm", { ...value });
    },
    async createReport(value) {
      try {
        let income = null;
        let spent = null;

        if (value.balance > this.previousBalance) {
          income = value.balance - this.previousBalance;
        } else {
          spent = this.previousBalance - value.balance;
        }
        await ApiService.post("/api/reports", {
          company_name: value.name,
          income: income,
          spent: spent,
          doctor: this.user.first_name + " " + this.user.last_name,
        });
      } catch (error) {
        console.error("Error creating report:", error);
      }
    },
  },
};
</script>

<style scoped></style>
