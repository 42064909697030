import Vue from "vue";
import VueRouter from "vue-router";
// eslint-disable-next-line no-unused-vars
import store from "../store";
import HomeView from "../views/HomeView.vue";
import PatientView from "@/views/PatientView";

import AdminProductsView from "@/views/admin/AdminProductsView.vue";
import AdminPatientsView from "@/views/admin/AdminPatientsView.vue";
import AdminCompaniesView from "@/views/admin/AdminCompaniesView.vue";
import AdminManagersView from "@/views/admin/AdminManagersView.vue";
import AdminPreferenceView from "@/views/admin/AdminPreferenceView.vue";
import AdminUsersView from "@/views/admin/AdminUsersView.vue";
import AdminProductCategoriesView from "@/views/admin/AdminProductCategoriesView.vue";

import CompanyProductsView from "@/views/company/CompanyProductsView.vue";
import CompanyPatientsView from "@/views/company/CompanyPatientsView.vue";
import CompanyUsersView from "@/views/company/CompanyUsersView.vue";

import NewUserView from "@/views/NewUserView";
import ReportsView from "@/views/ReportsView.vue";
import B2CView from "@/views/B2CView.vue";

import ForbiddenRoute from "./errorRoutes/ForbiddenRoute";
import NotFoundRoute from "./errorRoutes/NotFoundRoute";

import { ALL_ROLES } from "@/utils/constants";
import ManagerProductsView from "@/views/manager/ManagerProductsView.vue";
import ManagerProductCategoriesView from "@/views/manager/ManagerProductCategoriesView.vue";
import ManagerCompaniesView from "@/views/manager/ManagerCompaniesView.vue";
import ManagerUsersView from "@/views/manager/ManagerUsersView.vue";
import ManagerPatientsView from "@/views/manager/ManagerPatientsView.vue";

import PatientSessionView from "@/views/patient/PatientSessionView.vue";

import DeveloperView from "@/views/DeveloperView";
import SessionByUrlView from "@/views/SessionByUrlView";

Vue.use(VueRouter);

const routes = [
  /**
   * Routes for all users
   */
  {
    path: "/",
    name: "home",
    meta: {
      requiredRoles: ALL_ROLES,
    },
    component: HomeView,
  },
  {
    path: "/faq",
    name: "faq",
    meta: { requiredRoles: ALL_ROLES },
  },
  {
    path: "/noaccess",
    component: ForbiddenRoute,
    meta: { requiredRoles: ALL_ROLES },
  },
  {
    path: "*",
    component: NotFoundRoute,
    meta: { requiredRoles: ALL_ROLES },
  },
  /**
   * Admin routes
   */
  {
    path: "/admin",
    name: "admin",
    meta: { requiredRoles: ["admin"] },
  },
  {
    path: "/admin/products",
    name: "admin-products",
    meta: { requiredRoles: ["admin"] },
    component: AdminProductsView,
  },
  {
    path: "/admin/product-categories",
    name: "admin-product-categories",
    meta: { requiredRoles: ["admin"] },
    component: AdminProductCategoriesView,
  },
  {
    path: "/admin/managers",
    name: "admin-managers",
    meta: { requiredRoles: ["admin"] },
    component: AdminManagersView,
  },
  {
    path: "/admin/preference",
    name: "admin-preference",
    meta: { requiredRoles: ["admin"] },
    component: AdminPreferenceView,
  },
  {
    path: "/admin/companies",
    name: "admin-companies",
    meta: { requiredRoles: ["admin"] },
    component: AdminCompaniesView,
  },
  {
    path: "/admin/users",
    name: "admin-users",
    meta: { requiredRoles: ["admin"] },
    component: AdminUsersView,
  },
  {
    path: "/admin/users/new",
    name: "admin-new-user",
    meta: { requiredRoles: ["admin"] },
    component: NewUserView,
    props: { redirectBackTo: "admin-users" },
  },
  {
    path: "/admin/patients/:patient_id",
    name: "admin-patient",
    meta: { requiredRoles: ["admin"] },
    props: true,
    component: PatientView,
  },
  {
    path: "/admin/patients",
    name: "admin-patients",
    meta: { requiredRoles: ["admin"] },
    component: AdminPatientsView,
  },
  {
    path: "/admin/reports",
    name: "admin-reports",
    meta: { requiredRoles: ["admin"] },
    component: ReportsView,
  },
  {
    path: "/admin/b2c",
    name: "admin-bc2",
    meta: { requiredRoles: ["admin"] },
    component: B2CView,
  },
  /**
   * Company user and company owner routes
   */
  {
    path: "/my/products",
    name: "company-products",
    meta: { requiredRoles: ["company-user", "company-owner"] },
    component: CompanyProductsView,
  },
  {
    path: "/my/users",
    name: "company-users",
    meta: { requiredRoles: ["company-user", "company-owner"] },
    component: CompanyUsersView,
  },
  {
    path: "/my/users/new",
    name: "company-new-user",
    meta: { requiredRoles: ["company-owner"] },
    component: NewUserView,
    props: { redirectBackTo: "company-users" },
  },
  {
    path: "/my/patients/:patient_id",
    name: "company-patient",
    meta: {
      requiredRoles: ["company-user", "company-owner"],
    },
    props: true,
    component: PatientView,
  },
  {
    path: "/my/patients",
    name: "company-patients",
    meta: {
      requiredRoles: ["company-user", "company-owner"],
    },
    component: CompanyPatientsView,
  },
  {
    path: "/my",
    name: "customer",
    meta: { requiredRoles: ["company-owner", "company-user"] },
  },
  {
    path: "/my/reports",
    name: "company-reports",
    meta: { requiredRoles: ["company-owner"] },
    component: ReportsView,
  },
  /**
   * Manager routes
   */
  {
    path: "/manager",
    name: "manager",
    meta: { requiredRoles: ["manager"] },
  },
  {
    path: "/manager/products",
    name: "manager-products",
    meta: { requiredRoles: ["manager"] },
    component: ManagerProductsView,
  },
  {
    path: "/manager/product-categories",
    name: "manager-product-categories",
    meta: { requiredRoles: ["manager"] },
    component: ManagerProductCategoriesView,
  },
  {
    path: "/manager/companies",
    name: "manager-companies",
    meta: { requiredRoles: ["manager"] },
    component: ManagerCompaniesView,
  },
  {
    path: "/manager/users",
    name: "manager-users",
    meta: { requiredRoles: ["manager"] },
    component: ManagerUsersView,
  },
  {
    path: "/manager/patients/:patient_id",
    name: "manager-patient",
    meta: { requiredRoles: ["manager"] },
    props: true,
    component: PatientView,
  },
  {
    path: "/manager/patients",
    name: "manager-patients",
    meta: { requiredRoles: ["manager"] },
    component: ManagerPatientsView,
  },
  {
    path: "/manager/reports",
    name: "manager-reports",
    meta: { requiredRoles: ["manager"] },
    component: ReportsView,
  },
  {
    path: "/manager/b2c",
    name: "manager-b2c",
    meta: { requiredRoles: ["manager"] },
    component: B2CView,
  },
  /**
   * Patient routes
   */
  {
    path: "/patient/sessions",
    name: "patient-sessions",
    meta: { requiredRoles: ["patient"] },
    component: PatientSessionView,
  },
  /**
   * For debug
   */
  {
    path: "/developer",
    name: "developer",
    meta: { requiredRoles: ["admin"] },
    component: DeveloperView,
  },

  {
    path: "/session-by-url/:encrypted_patient_data",
    name: "session-by-url",
    meta: { requiredRoles: ["patient"] },
    props: true,
    component: SessionByUrlView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 10 Seconds timeout of waiting
const timeout_doGetUser = 10000;

// Function that will wait the user state
function userGetPromise(timeout) {
  var start = Date.now();
  return new Promise(waitForUser);

  function waitForUser(resolve, reject) {
    // store exists and user state too - this is our case to go forward
    if (router.app.$store && router.app.$store.state.user)
      resolve(router.app.$store.state.user);
    // time for waiting is up
    else if (timeout && Date.now() - start >= timeout) {
      reject();
    } else {
      // wait for 30ms and look for user again
      setTimeout(waitForUser.bind(this, resolve, reject), 30);
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.name == "home") return next();
  if (router.history._startLocation.includes("session-by-url")) {
    next();
    return;
  }

  var user = router.app.$store.state.user;
  if (!user) {
    // Making promise, to wait until the App will get the user state
    // To check it's role

    // Otherwise returning false (forbidden 403)
    await userGetPromise(timeout_doGetUser).then(() => {
      user = router.app.$store.state.user;
    });
  }
  if (user == undefined) return false;
  if (to.meta["requiredRoles"].includes(user.role)) next();
  else router.push("noaccess");
});

export default router;
